// Header.js
import React from "react";
import { FaPhone, FaEnvelope } from "react-icons/fa";
import navIcon1 from '../assets/img/nav-icon1.svg';
import navIcon2 from '../assets/img/nav-icon2.svg';
import navIcon3 from '../assets/img/nav-icon3.svg';

export const Header = () => {
  return (
    <>
      {/* Top 20px Container */}
      <header className="top-container">
      <div className="contact-info">
        <div className="contact-item contact1">
          <FaPhone className="icon" />
          <span className="contact-text">+44 7958 524020</span>
        </div>
        <div className="contact-item contact2">
          <FaEnvelope className="icon" />
          <span className="contact-text">cccoaching1@outlook.com</span>
        </div>
        
      </div>
      <div className="form-buttons">
      <div className="social-icon">
                            <a href='https://www.linkedin.com/company/pf-associates-ltd/' target="_blank" rel="noopener noreferrer" className="contact-item contact3"><img src={navIcon2} alt="Instagram" /></a>
                            <a href='https://www.linkedin.com/company/pf-associates-ltd/' target="_blank" rel="noopener noreferrer" className="contact-item contact4"><img src={navIcon3} alt="Facebook" /></a>
                            <a href='https://www.linkedin.com/company/pf-associates-ltd/' target="_blank" rel="noopener noreferrer" className="contact-item contact5"><img src={navIcon1} alt="LinkedIn" /></a>
                        </div>
        <a href="/privacy-policy" className="form-button contact-item contact2">
          Privacy Policy
        </a>
      </div>
    </header>

      
    </>
  );
};

