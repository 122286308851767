import React, { useState } from 'react';

export const FAQ = () => {
    const [activeIndex, setActiveIndex] = useState(null);

    const toggleFAQ = (index) => {
        setActiveIndex(activeIndex === index ? null : index);
    };

    const faqData = [
        {
            question: "What is executive coaching?",
            answer: "Executive coaching is a professional development process that helps individuals at the executive or senior management level enhance their leadership skills, improve their performance, and achieve their career goals.",
        },
        {
            question: "Who can benefit from executive coaching?",
            answer: "Executive coaching is beneficial for a wide range of individuals, including CEOs, executives, senior managers, entrepreneurs, and high-potential leaders.",
        },
        {
            question: "What are the benefits of executive coaching?",
            answer: "Benefits include improved leadership skills, increased self-awareness, enhanced decision-making, improved communication, increased productivity, reduced stress, improved work-life balance, and increased job satisfaction.",
        },
        {
            question: "How does executive coaching work?",
            answer: "Coaching typically involves a series of one-on-one sessions with a certified coach. Sessions may include discussions, goal setting, action planning, feedback, and support.",
        },
        {
            question: "How long does executive coaching typically last?",
            answer: "The duration of coaching programs can vary depending on individual needs and goals. Programmes can range from a few months to a year or longer.",
        },
        {
            question: "What is the investment for executive coaching?",
            answer: "The cost of executive coaching varies depending on the scope of the programme and the frequency of sessions. The price will be discussed and agreed before sessions commence.",
        },
        {
            question: "What is the difference between mentoring and coaching?",
            answer: "Mentoring typically involves a more informal relationship where a senior colleague provides guidance and support. Coaching is a more structured and goal-oriented process with a focus on personal and professional development.",
        },
        {
            question: "Is executive coaching confidential?",
            answer: "Yes, all coaching sessions are confidential. A contract will be agreed and will discuss confidentiality in more detail.",
        },
        {
            question: "What are the next steps?",
            answer: "Schedule a free consultation to discuss your specific needs and goals and learn more about how executive coaching can help you achieve your aspirations.",
        },
    ];

    return (
      <section>
        <div className="faqs-container">
            <h1 className="faqs-title">Frequently Asked Questions</h1>
            <div className="faqs-column">
                {faqData.map((faq, index) => (
                    <div
                        key={index}
                        className={`faq-card faq-card${index + 1} ${activeIndex === index ? 'active' : ''}`}
                    >
                        <div
                            className="faq-question-container"
                            onClick={() => toggleFAQ(index)}
                        >
                            <div className="faq-question">
                                <p>{faq.question}</p> </div>
                                <span className="faq-icon">
                                    {activeIndex === index ? '-' : '+'}
                                </span>
                            
                        </div>
                        {activeIndex === index && (
                            <div className="faq-answer">
                                <p>
                                {faq.answer}
                                </p>
                            </div>
                        )}
                    </div>
                ))}
            </div>
        </div>
        </section>
    );
};
