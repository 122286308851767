import React from "react";
import { useParams } from "react-router-dom"; // To capture the post ID from the URL
import blog1 from "../assets/img/blog1.jpg";
import blog2 from "../assets/img/blog2.jpg";

export const BlogPost = () => {
  const { postId } = useParams(); // Retrieve the post ID from the URL

  // Mock data for blog posts
  const posts = {
    1: {
      imageUrl: blog1,
      title: "The Power of Coaching: Reaching New Heights in Your Leadership Journey",
      date: "December 19, 2024",
      content: [
        "In today's dynamic world, the demands on leaders are constantly evolving. Navigating complex organisational landscapes, fostering high-performing teams, and driving innovation require more than just technical skills. It demands a deep understanding of oneself, a strategic mindset, and the resilience to thrive under pressure. Executive coaching can be a powerful catalyst for achieving extraordinary results. It provides a dedicated space for introspection, exploration, and growth, empowering leaders to:",
[ "Break through plateaus: Overcome limiting beliefs and unlock untapped potential",
  "Achieve peak performance: Enhance leadership presence, improve decision-making, and drive innovation. Enjoy quality time with family and friends",
  "Build high-performing teams: Foster a culture of collaboration, accountability, and high engagement.",
  "Navigate organisational change with confidence: Adapt to new challenges and lead through uncertainty.",
  "Create a lasting legacy: Make a significant and positive impact on your organisation."],
"Investing in coaching is not an expense, but an investment in your future. It's about unlocking your leadership potential, achieving your ambitious goals, and creating a lasting impact. Ready to take your leadership to new heights? I invite you to explore the transformative power of executive coaching. Schedule a free consultation or learn more about my services by emailing me of messaging me on Linkedin, Facebook or Instagram."
      ]
    },
    2: {
      imageUrl: blog2,
      title: "Feeling the Strain? It's Time to Invest in Yourself",
      date: "December 19, 2024",
      content: [
        "Let's be honest, leadership in today's fast-paced world can be incredibly demanding. You're juggling countless priorities, facing constant pressure to perform, and trying to maintain a semblance of work-life balance. It's easy to feel overwhelmed, stressed, and burnt out. But what if I told you there's a way to break free from the cycle of stress and achieve peak performance? What if you could lead with greater ease and confidence, while creating a life that truly fulfills you? Recent research has shown that executive coaching can have a profound impact on leaders, resulting in:",
[ "Improved leadership skills: Enhanced communication, decision-making, and emotional intelligence.",
"Increased self-awareness and confidence: Greater self-understanding and a stronger sense of self-efficacy.",
"Enhanced work-life balance: Reduced stress, improved well-being, and increased job satisfaction.",
"Increased productivity and performance: Improved team effectiveness and achievement of organisational goals.",
"Greater resilience and adaptability: Better ability to navigate change and overcome challenges."],
"As an executive coach, I've guided numerous leaders just like you through these challenges. I understand the unique pressures you face and the desire to make a lasting impact. Through our work together, we'll leverage the power of coaching to:",
[ "Develop your leadership presence: Enhance your communication, decision-making, and team-building skills",
"Build high-performing teams: Foster a culture of collaboration, trust, and mutual respect.",
"Achieve a fulfilling work-life balance: Reclaim your time and energy, and create a life that brings you joy.",
"Unlock your full potential: Achieve your career goals and make a significant contribution to your organisation."],
"Investing in yourself is the best investment you can make. It's about unlocking your full potential and creating a life that truly reflects your values and aspirations. Ready to take the first step? Schedule a free consultation today and let's explore how I can help you achieve your leadership goals and create a life you love. Schedule a free consultation or learn more about my services."
      ]
    }
  };

  // Get the specific post data based on postId
  const postData = posts[postId];

  // Handle case where postId is not found
  if (!postData) {
    return (
      <section>
        <div className="single-post">
          <main className="post-content">
            <div className="post-body">
              <h2>Post Not Found</h2>
              <p>The blog post you are looking for does not exist.</p>
            </div>
          </main>
        </div>
      </section>
    );
  }

  return (
    <div className="single-post">
      <main className="post-content">
        <div className="post-header">
          <img
            src={postData.imageUrl}
            alt={postData.title}
            className="post-image"
          />
          <h2 className="post-title">{postData.title}</h2>
          <p className="post-date">{postData.date}</p>
        </div>
        <div className="post-body">
          {postData.content.map((item, index) => {
            // Check if the item is a string (plain text)
            if (typeof item === "string") {
              return <p key={index}>{item}</p>;
            }

            // If it's an array, render it as a list
            if (Array.isArray(item)) {
              return (
                <ul key={index}>
                  {item.map((listItem, listIndex) => (
                    <li key={listIndex}>{listItem}</li>
                  ))}
                </ul>
              );
            }

            return null; // Return nothing if content is neither string nor array
          })}
        </div>
      </main>
    </div>
  );
};
