// HomePage.js
import React from 'react';
import { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import contact from '../assets/img/contactnob.PNG';
import safe from '../assets/img/safe.svg';
import target from '../assets/img/target.svg';
import graph from '../assets/img/graph.svg';
import medal from '../assets/img/medal.svg';
import book from '../assets/img/book.svg';
import portrait from '../assets/img/portrait.jpg';
import underline from '../assets/img/underline.svg';
import underlineblack from '../assets/img/underlineblack.svg';

export const HomePage = () => {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5
    },
    desktop: {
      breakpoint: { max: 3000, min: 1050 },
      items: 3
    },
    tablet: {
      breakpoint: { max: 1050, min: 768 },
      items: 2
    },
    mobile: {
      breakpoint: { max: 768, min: 0 },
      items: 1
    }
  };
  const features = [
    { heading: "Certified Expertise", text: "With extensive certifications and experience, I am fully dedicated to guiding you toward achieving your goals with confidence and care.", icon: medal },
    { heading: "Personalised Strategies", text: "Your journey is unique. I’ll customise our sessions to meet your individual needs, ensuring a tailored approach that works for you.", icon: target },
    { heading: "Science-Backed Methods", text: "Using evidence-based practices proven by research, I help you achieve meaningful, lasting progress that transforms your life.", icon: book },
    { heading: "Visible Results", text: "I focus on empowering you with practical tools and strategies to create measurable changes that last well beyond our sessions.", icon: graph },
    { heading: "Safe and Supportive Space", text: "Feel heard, understood, and valued in a judgment-free environment where you can freely share your thoughts and experiences.", icon: safe },
  ];

  const [visibleIndexes, setVisibleIndexes] = useState([]);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            const index = parseInt(entry.target.dataset.index, 10);
            setVisibleIndexes((prev) => [...new Set([...prev, index])]);
          }
        });
      },
      { threshold: 0.5 }
    );
    

    const elements = document.querySelectorAll(".feature");
    elements.forEach((el, index) => {
      el.dataset.index = index;
      observer.observe(el);
    });
    

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  useEffect(() => {
    const observer = new IntersectionObserver(
      (entries) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("fade-in");
          }
        });
      },
      { threshold: 0.5 }
    );

    // Select both the image container and content elements
    const elements = document.querySelectorAll(
      ".therapist-image-container, .therapist-content, .service-cards, .review-bx"
    );

    elements.forEach((el) => observer.observe(el));

    return () => {
      elements.forEach((el) => observer.unobserve(el));
    };
  }, []);

  return (
    <div className="homepage">
      
      {/* Welcome Section */}
<section className="welcome-section">
  <div className="welcome-image">
    <div className="welcome-overlay"></div>
  </div>
  <div className="welcome-text">
    <h1>C.C Coaching U.K Ltd.</h1>
    <img src={underline} className='welcome-underline' alt='underline'></img>
    <p>
      <strong>Executive Coach: </strong>
  Helping Healthcare Leaders & Senior Professionals Achieve Peak Performance 
  & Lasting Fulfilment in and out of work. Transform Your Leadership
  from Overwhelmed, to Empowered.</p>
  </div>
</section>


      {/* Meet Your Therapist */}
      <section className="meet-therapist">
        <div className="therapist-image-container">
          <img src={portrait} alt="Therapist" className="therapist-image"/>
        </div>
        <div className="therapist-content">
          <h2>Meet Your Personal Coach</h2>
          <img src={underlineblack} className='therapist-underline' alt='underline'></img>
          <p>Hello, my name is Carolyn Cowperthwaite and I am a trained Executive Coach. I help managers with obtaining promotion and assist them to lead with confidence, build high performing teams whilst achieving a positive work/life balance. My passion is helping others to overcome their challenges to create the life they want.</p>
        </div>
      </section>

      <section className='journey'>
        <div>
          <h2>Leading with Purpose</h2>
          <p><strong>My mission:</strong> Helping doctors and senior professionals to lead with confidence in order to be happy both personally and professionally.</p>

          <h2>My Journey: Empowering Leaders to Thrive</h2>
          <p>
          As a seasoned leader with over a decade of senior leadership experience in the demanding healthcare environment, I understand the unique challenges you face. From the constant pressure to deliver results to the complexities of managing teams and navigating organisational change, it's easy to feel overwhelmed and burned out.</p>
          <p>
            
I've been there. I've experienced the stress, the self-doubt, and the struggle to maintain a healthy work-life balance. That's why I'm passionate about helping leaders like you overcome these challenges and thrive.
Through my executive coaching, I help you to: </p>
          <ul>
            <p>
            <li> <strong>Enhance your leadership presence:</strong> Develop strong communication, decision-making, and team-building skills.</li>
<li><strong>Improve your work-life balance:</strong>Create boundaries, prioritise your well-being, and achieve a fulfilling life outside of work.</li>
<li><strong>Navigate complex organisational challenges:</strong> Build resilience, adapt to change, and lead with confidence and clarity.</li>
<li><strong>Unlock your full potential:</strong> Achieve your career goals and make a lasting impact on your organisation.</li>
            </p>
          </ul>
          <p>
          Drawing on my deep understanding of leadership dynamics and my own experiences navigating challenging environments, I empower you to lead with purpose and achieve lasting success.
          </p>
          <p>Let's work together to transform your leadership journey.</p>
        </div>
      </section>

      {/* Why Choose Me */}
      <section className="services">
        <h2>Services Offered</h2>
        <div
            className="service-cards"
          >
          <div className="service-card">
            <h3>Individual Coaching</h3>
            <p>Personalised one-on-one sessions to help you achieve your career and personal growth goals.</p>
          </div>
          <div className="service-card">
            <h3>Masterclass/Mastermind programme</h3>
            <p>Year long coaching programme tailored for leaders to enhance leadership skills and drive organisational success.</p>
          </div>
          <div className="service-card">
            <h3>Team Coaching</h3>
            <p>Strengthen team dynamics and improve collaboration with our specialised team coaching sessions.</p>
          </div>
        </div>
      </section>

      {/* Why Choose Me */}
      <section className="why-choose">
      <h2>Why Choose Me?</h2>
      <div className="features-section">
        {features.map((feature, index) => (
          <div
            key={index}
            className={`feature ${
              visibleIndexes.includes(index) ? "fade-in" : ""
            }`}
          >
            <img src={feature.icon} alt={feature.heading} className="ficon" />
            <h3 className="heading">{feature.heading}</h3>
            <p className="text">{feature.text}</p>
          </div>
        ))}
      </div>
    </section>

      <section className="review" id="reviews">
            <Row>
                <Col>
                <div className="review-bx">
                    <h2>
                        Testimonials
                    </h2>
                    <Carousel responsive={responsive} infinite={true} className="review-slider">
                        <div className="item">
                          <p>“I was cynical about the process at first, but in all honesty, have found a lot of value in the group and 1:1 coaching with Carolyn as a team but also on an individual level, I feel safer and happier. Thank you! I am hopeful that we will continue to grow as a team of leaders and this process has been the driver behind our work so far.”
                          </p>
                        </div>
                        <div className="item">
                            <p>“This process has allowed protected and focused time to reflect on our own leadership behaviours and how impactful they can be on others. I realise how working together, we can become stronger.”</p>
                        </div>
                        <div className="item">
                            <p>“Identifying individual colleagues strengths and how we can make the most of these to have the most success and impact as a leadership team has been the catalyst for what we hope will lead to real positive and impactful changes.”</p>
                        </div>
                        <div className="item">
                            <p>“This programme has helped to evaluate where we are as a team. Confidential conversations with someone outside of the organisation has opened room for honesty and helped us look at areas that need addressing. Structured sessions with plans and clear goal setting for te team has been so beneficial.”</p>
                        </div>
                        <div className="item">
                            <p>“This coaching programme has brought us together as a team and we had some difficult relationships previously. We have had a combination of group and 1:1 coaching sessions which have really helped me along the journey. It has helped me to prioritise and formulate actions.“</p>
                        </div>
                        <div className="item">
                            <p>“Carolyn has kept us on track as a team ensuring we left all of our sessions with clear actions. Carolyn is very easy to engage with, was very responsive and had the skills we needed to support us as a team to become united. Carolyn listened to what we needed and delivered effectively.”
                            </p>
                        </div>
                        <div className="item">
                            <p>“We have experienced a non-judgemental environment for the team to openly discuss issues. 1:1 sessions with Carolyn, have been empowering and gives time to reflect on career development, own successes and identify areas to work on.“
                            </p>
                        </div>
                        </Carousel>
                </div>
                </Col>
            </Row>
    </section>
      {/* Contact Us */}
      <section className="home-contact">
  <div className="contact-wrapper">
    <div className="contact-content contact-link">
      <h2>Contact Us</h2>
      <p>
        Ready to take the next step? Reach out to us today and let us help you achieve your goals.
      </p>
      <a href="/contact">
      <button type="submit">
        <span>Get In Touch</span>
      </button>
      </a>
    </div>
    <div className="contact-logo">
      <a href="/contact">
        <img src={contact} alt="Contact" />
      </a>
    </div>
  </div>
</section>
    </div>
  );
};