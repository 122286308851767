import React from 'react';
import leadership from '../assets/img/leader.svg';
import growth from '../assets/img/growth.png';
import communication from '../assets/img/discussion.png';
import balance from '../assets/img/balance.png';
import quote from '../assets/img/quote.PNG';

export const Services = () => {
    return (
      <section className='coaching' id='coaching'>
        <div className="coaching-page">
            {/* About Coaching Section */}
            <section id="about-coaching" className="about-coaching">
                <div className="container">
                    <h2>Transform Your Career with Professional Coaching</h2>
                </div>
            </section>


            {/* Why Coaching Section */}
            <section id="why-coaching" className="why-coaching">
                <h2>Why Choose Coaching?</h2>
                <div className="benefits">
                    <div className="benefit">
                        <img src={leadership} alt="Leadership Icon" className="ficon"/>
                        <h3>Leadership Development</h3>
                        <p>Enhance your ability to inspire and lead effectively.</p>
                    </div>
                    <div className="benefit">
                        <img src={communication} alt="Communication Icon" className="ficon"/>
                        <h3>Communication Skills</h3>
                        <p>Master the art of clear, impactful communication.</p>
                    </div>
                    <div className="benefit">
                        <img src={growth} alt="Growth Icon" className="ficon"/>
                        <h3>Career Growth</h3>
                        <p>Identify opportunities and strategise for long-term success.</p>
                    </div>
                    <div className="benefit">
                        <img src={balance} alt="Balance Icon" className="ficon"/>
                        <h3>Work-Life Balance</h3>
                        <p>Achieve harmony between your personal and professional goals.</p>
                    </div>
                </div>
            </section>

            {/* Types of Coaching Services Section */}
            <section id="coaching-services" className="coaching-services">
                <h2>Explore Our Coaching Services</h2>
                <div className="services-grid">
                    {/* Individual Coaching */}
                    <div className="service">
                        <h3>Individual Coaching</h3>
                        <p>
                        Personalised one-on-one sessions to help you achieve your career and personal growth goals.
                        </p>
                    </div>
                    {/* Executive Coaching */}
                    <div className="service">
                        <h3>Masterclass/Mastermind programme</h3>
                        <p>
                        Year long coaching programme tailored for leaders to enhance leadership skills and drive organisational success.
                        </p>
                    </div>
                    {/* Team Coaching */}
                    <div className="service">
                        <h3>Team Coaching</h3>
                        <p>
                        Strengthen team dynamics and improve collaboration with our specialised team coaching sessions.
                        </p>
                    </div>

                    
                </div>
            </section>

            {/* How It Works Section */}
            <section id="how-it-works" className="how-it-works">
                <h2>How It Works</h2>
                <div className='how-it-works-container'>
                <ul className="how-it-works-steps">
                    <li>
                        <h3>Step 1: Schedule a Consultation</h3>
                        <p>We will discuss your goals and challenges in a one-on-one session.</p>
                    </li>
                    <li>
                        <h3>Step 2: Tailored Coaching Plan</h3>
                        <p>Receive a customised program designed to meet your unique needs.</p>
                    </li>
                    <li>
                        <h3>Step 3: Implement and Grow</h3>
                        <p>Apply new strategies and skills to achieve measurable results.</p>
                    </li>
                </ul>
                <img src={quote} alt='quote' className='how-it-works-img'/>
                </div>
            </section>

            {/* Testimonials Section */}
            <section id="services-testimonials" className="services-testimonials">
                <h2>What Our Clients Say</h2>
                <div className="services-testimonial">
                    <blockquote>
                        "1:1 coaching with Carolyn as a team but also on an individual level, I feel safer and happier. Thank you!"
                    </blockquote>
                </div>
                <div className="services-testimonial">
                    <blockquote>
                        "This process has been the driver behind our work so far!"
                    </blockquote>
                </div>
                <div className="services-testimonial">
                    <blockquote>
                        "Identifying individual colleagues strengths and how we can make the most of these to have the most success and impact as a leadership team has been the catalyst for what we hope will lead to real positive and impactful changes."
                    </blockquote>
                </div>
                <div className="services-testimonial">
                    <blockquote>
                        "We have experienced a non-judgemental environment for the team to openly discuss issues. 1:1 sessions with Carolyn, have been empowering and gives time to reflect on career development, own successes and identify areas to work on."
                    </blockquote>
                </div>
                <div className="services-testimonial">
                    <blockquote>
                        "This coaching programme has brought us together as a team and we had some difficult relationships previously."
                    </blockquote>
                </div>
            </section>

            {/* Call to Action */}
            <section id="call-to-action" className="call-to-action">
                <h2>Ready to Take the Next Step?</h2>
                <p>Join the countless professionals who have unlocked their potential with <strong>C.C Coaching U.K</strong>.</p>
                <a href="/contact">
                <button type="submit">
                <span>Get In Touch</span>
                </button>
                </a>
            </section>
        </div>
      </section>
    );
};
