
import React from 'react';

export const Packages = () => {
    const packages = [
        {
            title: "Individual Coaching",
            description: "Personalised one-on-one sessions to help you achieve your career and personal growth goals.",
            price: "£1,200 for a three month plan",
            features: [
                "Personalised coaching plan",
 "90 minute deep dive questionnaire to explore your challenges and set some clear goals.",
 "Review of your questionnaire with a video response with my reflections in preparation for our first session.",
 "90 minute session to unpack what is going on for you and map out your next steps.",
 "12 weeks of coaching support with regular check-ins (by email or voice note) plus 3 x 60 minute sessions.",
 "Email support between sessions",
 "End of programme review: We will review what has progressed and ensure you have a clear plan ahead."
            ],
        },
        {
            title: "Team Coaching",
            description: "Strengthen team dynamics and improve collaboration with our specialised team coaching sessions.",
            price: "£5,000 for a 6 month programme",
            features: [
                "Initial assessment to identify the scope of the personalised programme.",
"Customised team assessments with 1:1 initial coaching sessions",
"Team away sessions for team analysis, visioning, strategy and action planning",
"Conflict resolution strategies",
"Policy reviews as required to support new processes.",
"Leadership development for team leaders with Masterclass sessions.",
            ],
        },
        {
            title: "Masterclass/Mastermind programme (Minimum 10 participants).",
            description: "Year long coaching programme tailored for leaders to enhance leadership skills and drive organisational success.",
            price: "£2,000 per participant",
            features: [
                "Executive-level goal setting with strategic planning.",
 "Confidential one-on-one sessions",
 "Strategic decision-making support",
 "Work-life balance and team building techniques",
 "10 Expert educational sessions (topics tailored to the needs of the organisation and participants",
 "10 Mastermind sessions to support the educational masterclasses. (Optional)",
 "Plus email support when required.",
 "Access to work books to support learning and action planning and reviews.",
            ],
        },
    ];

    return (
      <div>
      <section>
        <div className="packages-container">
            <h1 className="packages-title">Coaching Packages</h1>
            <p className="packages-subtitle">Choose the coaching package that suits your needs and start your journey to success today.</p>
            <div className="packages-grid">
                {packages.map((pkg, index) => (
                    <div key={index} className={`package-card package-card${index + 1}`}>
                        <h2 className="package-title">{pkg.title}</h2>
                        <p className="package-description">{pkg.description}</p>
                        <p className="package-price">{pkg.price}</p>
                        <ul className="package-features">
                            {pkg.features.map((feature, i) => (
                                <li key={i}>{feature}</li>
                            ))}
                        </ul>
                    </div>
                ))}
            </div>
        </div>
        </section>
        <section id="call-to-action" className="call-to-action">
          <h2>Ready to Take the Next Step?</h2>
          <p>Join the countless professionals who have unlocked their potential with <strong>C.C Coaching U.K</strong>.</p>
          <a href="/contact">
            <button type="submit">
            <span>Get In Touch</span>
            </button>
          </a>
        </section>
        </div>
    );
};
