import React from "react";
import { Link } from "react-router-dom"; // For navigation between pages

export const Blog = () => {
  return (
    <section>
    <div className="blog-page">
      {/* Your Navbar and Header components will be here, since you already have them */}
      <main className="main-content">
      <h2 className="blog-title">Blog Posts</h2>
        <div className="featured-posts">
          {/* Blog Post 1 */}
          <Link to="/post/1" className="featured-post-link">
          <article className="featured-post featured-post1">
            <h2 className="post-title">The Power of Coaching: Reaching New Heights in Your Leadership Journey</h2>
            <p className="post-date">December 19, 2024</p>
            <p className="post-excerpt">
            In today's dynamic world, the demands on leaders are constantly evolving. Navigating complex organisational landscapes, fostering high-performing teams, and driving innovation require more than just technical skills. It demands a deep understanding of oneself, a strategic mindset, and the resilience to thrive under pressure.
            </p>
            <p className="post-excerpt"><strong>Click the title to read the full post.</strong></p>
          </article>
          </Link>

          {/* Blog Post 2 */}
          <Link to="/post/2" className="featured-post-link">
          <article className="featured-post featured-post2">
            <h2 className="post-title">Feeling the Strain? It's Time to Invest in Yourself</h2>
            <p className="post-date">December 19, 2024</p>
            <p className="post-excerpt">
              Let's be honest, leadership in today's fast-paced world can be incredibly demanding. You're juggling countless priorities, facing constant pressure to perform, and trying to maintain a semblance of work-life balance. It's easy to feel overwhelmed, stressed, and burnt out.
              But what if I told you there's a way to break free from the cycle of stress and achieve peak performance? What if you could lead with greater ease and confidence, while creating a life that truly fulfills you?
            </p>
            <p className="post-excerpt"><strong>Click the title to read the full post.</strong></p>
          </article>
          </Link>
        </div>
      </main>

      {/* Your Footer component will be here */}
    </div>
    </section>
  );
};
