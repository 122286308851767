
import 'react-multi-carousel/lib/styles.css';
import React from 'react';
import quote from '../assets/img/reshot-icon-customer-review-5PYD2BTJAZ.svg';

export const Testimonials = () => {

    return(
      <section class="testimonials">
      <h2>What Our Clients Say</h2>
      

      <div class="testimonial testimonial-1">
          <div class="testimonial-content">
              <blockquote>
              “I was cynical about the process at first, but in all honesty, have found a lot of value in the group and 1:1 coaching with Carolyn as a team but also on an individual level, I feel safer and happier. Thank you!
              I am hopeful that we will continue to grow as a team of leaders and this process has been the driver behind our work so far.”
              </blockquote>
          </div>
          <img src={quote} className="ticon" alt='quote'/>
      </div>
  

      <div class="testimonial testimonial-2">
          <div class="testimonial-content">
              <blockquote>
              “This process has allowed protected and focused time to reflect on our own leadership behaviours and how impactful they can be on others. I realise how working together, we can become stronger.”
              </blockquote>
          </div>
          <img src={quote} className="ticon" alt='quote'/>
      </div>
  

      <div class="testimonial testimonial-3">
          <div class="testimonial-content">
              <blockquote>
              “Identifying individual colleagues strengths and how we can make the most of these to have the most success and impact as a leadership team has been the catalyst for what we hope will lead to real positive and impactful changes.”
              </blockquote>
          </div>
          <img src={quote} className="ticon" alt='quote'/>
      </div>
  

      <div class="testimonial testimonial-4">
          <div class="testimonial-content">
              <blockquote>
              “This programme has helped to evaluate where we are as a team. Confidential conversations with someone outside of the organisation has opened room for honesty and helped us look at areas that need addressing.
Structured sessions with plans and clear goal setting for te team has been so beneficial.
We have experienced a non-judgemental environment for the team to openly discuss issues.
1:1 sessions with Carolyn, have been empowering and gives time to reflect on career development, own successes and identify areas to work on.”
</blockquote>
          </div>
          <img src={quote} className="ticon" alt='quote'/>
      </div>
  

      <div class="testimonial testimonial-5">
          <div class="testimonial-content">
              <blockquote>
              “This coaching programme has brought us together as a team and we had some difficult relationships previously. We have had a combination of group and 1:1 coaching sessions which have really helped me along the journey. It has helped me to prioritise and formulate actions.
Carolyn has kept us on track as a team ensuring we left all of our sessions with clear actions.
Carolyn is very easy to engage with, was very responsive and had the skills we needed to support us as a team to become united.
Carolyn listened to what we needed and delivered effectively.”
              </blockquote>
          </div>
          <img src={quote} className="ticon" alt='quote'/>
      </div>
  </section>
  
    )
}